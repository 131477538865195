
import { defineComponent } from 'vue';
import BaseModalWithoutThumbnail from '@/components/Base/BaseModalWithoutThumbnail.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import IconChevronDown from '@/components/Icon/IconChevronDown.vue';
import {
  getIsFistShowIntroModal,
  setIsFistShowIntroModal,
} from '@/helpers/localStorage';

export default defineComponent({
  name: 'IntroModal',
  components: { IconChevronDown, BaseButton, BaseModalWithoutThumbnail },
  data: () => ({
    modalIntro: false,
    modalImg: false,
  }),
  mounted() {
    this.checkShowModal();
  },
  methods: {
    checkShowModal() {
      if (!getIsFistShowIntroModal()) {
        setTimeout(() => {
          this.modalIntro = true;
        }, 300);
        setIsFistShowIntroModal(true);
      }
    },
    handleModalIntro(status: boolean): void {
      this.modalIntro = status;
    },
    handleClickViewDetail(): void {
      this.modalImg = true;
    },
    handleCloseViewDetail(): void {
      this.modalImg = false;
    },
  },
});
