<template>
  <div class="dashboard-overview bg-primary text-white relative">
    <p>Tổng tài sản</p>
    <h2 class="mt-1" v-if="user.totalInvestProfit">
      {{ numberToMoney(user.totalInvestProfit) }} đ
    </h2>
    <p class="tab-inactive" v-else>Bạn chưa đầu tư</p>
    <div class="dahsboard-overview-robot absolute">
      <img src="@/assets/images/app_logo_rolate.png" class="relative z-10" />
      <div class="blur"></div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { numberToMoney } from '@/utils/index';
export default defineComponent({
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  data() {
    return {
      numberToMoney,
    };
  },
});
</script>

<style lang="scss" scoped>
.dashboard-overview {
  height: 110px;
  border-radius: 25px;
  padding: 30px 25px;
  overflow: hidden;
  .dahsboard-overview-robot {
    width: 136px;
    right: -20px;
    top: 8px;
    z-index: 1;
  }
  .blur {
    position: absolute;
    width: 194px;
    height: 194px;
    background: #def5e9;
    border-radius: 100%;
    top: 0;
    right: 0;
    z-index: 0;
    filter: blur(70px);
    -webkit-filter: blur(40px);
  }
}
</style>
