
/*eslint-disable*/
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import DashboardOverview from './DashboardOverview.vue';
import { NAME_APP } from '@/constants';
// import DashboardMainFunction from './DashboardMainFunction.vue';
// import DashboardMoreInfo from './DashboardMoreInfo.vue';
// import FinanceHealth from './FinanceHealth.vue';
// import Partner from './DashboardPartner.vue';
import InvestmentPackageItem from '../InvestmentPackage/InvestmentPackageItem.vue';
import { listPools } from '@/apis/ApiPool';
import SketetonLoader from '@/components/Base/BaseSkeletonLoader.vue';
// import { listInvestmentPackage } from '@/apis/ApiContract';
import IntroModal from '@/components/IntroModal.vue';
import { mapMutations, mapGetters } from 'vuex';
export default defineComponent({
  name: 'Dashboard',
  components: {
    IntroModal,
    DefaultLayout,
    DashboardOverview,
    SketetonLoader,
    // DashboardMainFunction,
    // FinanceHealth,
    // Partner,
    // DashboardMoreInfo,
    InvestmentPackageItem,
  },
  setup() {
    return {};
  },
  data: () => ({
    packageList: null,
    page: 1,
    limit: 10,
    NAME_APP,
    loading: false,
  }),
  created() {
    if (this.poolList.length < 1) {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters({
      poolList: 'getPoolList',
    }),
  },
  methods: {
    ...mapMutations({
      setPoolList: 'setPoolList',
    }),
    fetchData() {
      this.loading = true;
      listPools()
        .then((res) => {
          this.setPoolList(res.data);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log('err', err);
        });
    },
  },
});
