<template>
  <div class="relative z-20">
    <div
      v-if="showModel"
      class="fixed w-full inset-0 z-20"
      style="background: rgba(205, 206, 219, 0.6); backdrop-filter: blur(12px)"
    />
    <transition :name="transitionStyle">
      <div
        v-if="showModel"
        class="
          flex
          items-center
          fixed
          w-full
          inset-0
          overflow-hidden
          justify-center
          items-start
          z-30
        "
        @click="modalClose"
      >
        <div
          v-if="showModel"
          class="content-model mt-4 overflow-hidden md:max-w-md mx-2 shadow-lg"
          @click.stop
        >
          <div class="modal-content overflow-y-auto text-center">
            <!--Header-->
            <div class="header my-6">
              <slot name="header" />
            </div>
            <!--Body-->
            <slot />
            <!--Footer-->
            <div class="footer py-6">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'BaseModalWithoutThumbnail',
  props: {
    title: {
      type: String,
    },
    showModel: {
      type: Boolean,
      default: true,
    },
    modalClose: {
      type: Function,
    },
    transitionStyle: {
      type: String,
      default: 'slideDown',
    },
  },
  watch: {
    showModel(value) {
      if (value) {
        document.querySelector('body').classList.add('modal-open');
      } else {
        document.querySelector('body').classList.remove('modal-open');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content-model {
  max-width: 400px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 25px;
  .icon-background {
    position: absolute;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    top: -110px;
    img {
      margin: 0 auto;
    }
    .icon {
      position: absolute;
      width: 150px;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .modal-content {
    max-height: 100vh;
  }
}
</style>
